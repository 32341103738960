import axios from "../../axios-middleware";
import HELPERS from "@/modules/OperatorPanel/helpers";
import i18n from "@/lang/i18n.js";
import Vue from "vue";

const state = {
  operator_panel: {
    active_chat: {
      participants: [

      ],
      titleImageUrl:
        "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
      messageList: [

      ],
			messageListScrollDisabled: false,
			client_id: null,
			status_chat: null,
      replyMessageData: null,

      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: "",
      colors: null,
      availableColors: {
        red: {
          header: {
            bg: "#D32F2F",
            text: "#fff",
          },
          launcher: {
            bg: "#D32F2F",
          },
          messageList: {
            bg: "#fff",
          },
          sentMessage: {
            bg: "#F44336",
            text: "#fff",
          },
          receivedMessage: {
            bg: "#eaeaea",
            text: "#222222",
          },
          userInput: {
            bg: "#fff",
            text: "#212121",
          },
          userList: {
            bg: "#fff",
            text: "#212121",
          },
        },
        green: {
          header: {
            bg: "#388E3C",
            text: "#fff",
          },
          launcher: {
            bg: "#388E3C",
          },
          messageList: {
            bg: "#fff",
          },
          sentMessage: {
            bg: "#4CAF50",
            text: "#fff",
          },
          receivedMessage: {
            bg: "#eaeaea",
            text: "#222222",
          },
          userInput: {
            bg: "#fff",
            text: "#212121",
          },
          userList: {
            bg: "#fff",
            text: "#212121",
          },
        },
        blue: {
          header: {
            bg: "#4e8cff",
            text: "#ffffff",
          },
          launcher: {
            bg: "#4e8cff",
          },
          messageList: {
            bg: "#ffffff",
          },
          sentMessage: {
            bg: "#4e8cff",
            text: "#ffffff",
          },
          receivedMessage: {
            bg: "#eaeaea",
            text: "#222222",
          },
          userInput: {
            bg: "#f4f7f9",
            text: "#565867",
          },
          userList: {
            bg: "#fff",
            text: "#212121",
          },
        },
        dark: {
          header: {
            bg: "#34495e",
            text: "#ecf0f1",
          },
          launcher: {
            bg: "#34495e",
          },
          messageList: {
            bg: "#2c3e50",
          },
          sentMessage: {
            bg: "#7f8c8d",
            text: "#ecf0f1",
          },
          receivedMessage: {
            bg: "#95a5a6",
            text: "#ecf0f1",
          },
          userInput: {
            bg: "#34495e",
            text: "#ecf0f1",
          },
          userList: {
            bg: "#2c3e50",
            text: "#ecf0f1",
          },
        },
      },
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      showEdition: false,
      showDeletion: false,
      disableUserListToggle: false,
      placeholder: "Write a message...",
      title: "Chat title",
      showFile: false,
      showEmoji: false,
      showLauncher: true,
      showCloseButton: false,
      quickReplies: [],
			quickRepliesIsPrompt: [],
      quickReplyCategories: [],
			replies_mode: null,
      isQuickRepliesActive: false,
      isAutoRepliesActive: false,
      quickReplyCategory: null,
      chatStatuses: [],
			hideSystemMessages: false,

			emailContactsList: [],
			emailUserInput: {
				replyTo: [],
				forwardTo: [],
			},

			chatRoomInfo: {},
    },
    chatDisplayMode: "frame", // frame||widget
		chatRoomTransfers: {},
  },
};

const mutations = {
  OP_SET_CHAT_ROOM_MESSAGES(state, payload) {
    const {messages, roomId} = payload

    const userId = state.operator_panel.userInfo.id;
    const options = { messages, userId };

    if (roomId === state.operator_panel.activeChatRoom) {
      state.operator_panel.active_chat.messageList = HELPERS.formatDialogMsgs(options);
    }
  },
	OP_SET_CHAT_ROOM_CLIENT_ID(state, payload) {
		state.operator_panel.active_chat.client_id = payload
	},
	OP_SET_CHAT_ROOM_STATUS(state, payload) {
		state.operator_panel.active_chat.status_chat = payload
	},
  OP_CLEAR_CHAT_ROOM_MESSAGES(state) {
    state.operator_panel.active_chat.messageList = [];
  },
  OP_SET_QUICK_REPLIES(state, list) {
    state.operator_panel.active_chat.quickReplies = list;
  },
	OP_SET_QUICK_REPLIES_IS_PROMPT(state, list) {
		state.operator_panel.active_chat.quickRepliesIsPrompt = list;
	},
  OP_SET_QUICK_REPLY_CATEGORIES(state, list) {
    state.operator_panel.active_chat.quickReplyCategories = list;
  },
  OP_ACTIVATE_QUICK_REPLIES(state, boolean) {
    state.operator_panel.active_chat.isQuickRepliesActive = boolean;
  },
  OP_ACTIVATE_AUTO_REPLIES(state, boolean) {
    state.operator_panel.active_chat.isAutoRepliesActive = boolean;
  },
	OP_SET_REPLIES_MODE(state, value) {
		state.operator_panel.active_chat.replies_mode = value;
	},
  OP_SET_QUICK_REPLY_CATEGORY(state, category) {
    state.operator_panel.active_chat.quickReplyCategory = category;
  },

  OP_SET_CHAT_STATUSES(state, statuses) {
    state.operator_panel.active_chat.chatStatuses = statuses;
  },

  OP_SET_REPLY_MESSAGE_DATA(state, data) {
    state.operator_panel.active_chat.replyMessageData = data;
  },
	OP_SET_CHAT_ROOM_TRANSFERS(state, data) {
		state.operator_panel.chatRoomTransfers = data;
	},
	OP_TOGGLE_HIDE_SYSTEM_MESSAGES(state, value) {
		state.operator_panel.active_chat.hideSystemMessages = value;
	},
	OP_SET_MESSAGE_LIST_SCROLL_DISABLED(state, boolean){
		state.operator_panel.active_chat.messageListScrollDisabled = boolean;
	},

	OP_SET_EMAIL_CONTACTS_LIST(state, payload) {
		state.operator_panel.active_chat.emailContactsList = payload;
	},
	OP_SET_EMAIL_REPLY_TO(state, payload) {
		state.operator_panel.active_chat.emailUserInput.replyTo = payload;
	},
	OP_SET_EMAIL_FORWARD_TO(state, payload) {
		state.operator_panel.active_chat.emailUserInput.forwardTo = payload;
	},

	OP_UPDATE_CHAT_ROOM_MESSAGE_DATA(state, payload) {
		const { data } = { ...payload }
		const messages = [data]
		const userId = state.operator_panel.userInfo.id;
		const options = { messages, userId };
		const formattedMsg = HELPERS.formatDialogMsgs(options);
		const msgIndex = state.operator_panel.active_chat.messageList
			.findIndex(item => item.id === data.id)

		//set text
		Vue.set(state.operator_panel.active_chat.messageList[msgIndex].data, 'text', formattedMsg[0].data.text)
		// set transcription
		Vue.set(state.operator_panel.active_chat.messageList[msgIndex].data, 'transcription', formattedMsg[0].data.transcription)
		// set transcription
		Vue.set(state.operator_panel.active_chat.messageList[msgIndex].data, 'original_data', formattedMsg[0].data?.original_data)
		// set message_status
		Vue.set(state.operator_panel.active_chat.messageList[msgIndex].data, 'message_status', formattedMsg[0].data?.message_status)
	},

	OP_SET_ACTIVE_CHAT_INFO(state, payload) {
		state.operator_panel.active_chat.chatRoomInfo = payload;
	}
};

const actions = {
  async OpAxiosGetChatRoomMessages({ commit }, chatRoomId) {
    const roomId = parseInt(chatRoomId);

    if (!roomId) return [];

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${roomId}/messages/list/`;

    const { messages, status_chat, participants }  = await axios
      .get(url)
      .then((response) => {
        return {
					messages: response.data?.data?.messages || [],
					status_chat: response.data?.data?.status,
					participants: response.data?.data?.participants || [],
				}
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_CHAT_ROOM_MESSAGES", {messages, roomId});
		commit("OP_SET_CHAT_ROOM_STATUS", status_chat);

		return participants
  },
	OpUpdateChatRoomMessage({ commit }, payload) {
		commit('OP_UPDATE_CHAT_ROOM_MESSAGE_DATA', payload)
	},
	async OpAxiosGetChatRoomClientId({ commit }, chatRoomId) {
		const roomId = parseInt(chatRoomId);

		if (!roomId) return [];

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${roomId}/messages/list/`;

		const {client_id, status_chat} = await axios
			.get(url)
			.then((response) => {
				const participants = response.data?.data?.participants || [];
				const isClient = (participant) => participant.type === 'client';

				return {
					client_id: participants.find(isClient)?.client_id || {},
					status_chat: response.data?.data?.status,
				}
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});


		commit("OP_SET_CHAT_ROOM_CLIENT_ID", client_id);
		commit("OP_SET_CHAT_ROOM_STATUS", status_chat);
	},
	async OpAxiosGetChatRoomMessagesLimit({ _ }, chatRoomId) {
		const roomId = parseInt(chatRoomId);

		if (!roomId) return [];

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${roomId}/messages/list/?limit=1`;

		return await axios
			.get(url)
			.then((response) => {
				return response.data?.data?.messages
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});
	},

  async OpAxiosSendMessageToChat({ dispatch }, messageObj) {
    dispatch("updateAjaxDialog", [true, true]);

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/message/add/`;

    let request;

    switch (messageObj.message_type) {
      case "file":
        request = HELPERS.toFormDataFiles(messageObj);
        break;

      default:
        request = messageObj;
        break;
    }

    const success = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
            i18n.t("common.error"),
            i18n.t("modules.op.texts.send_error"),
        ]);
        return false;
      });

    return success;
  },

	async OpAxiosSendTemplateToChat({dispatch}, {messageObj, bot_id}) {
		dispatch("updateAjaxDialog", [true, true]);
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/whatsapp/template_send/${bot_id}/`;

		const request = {...messageObj}

		const success = await axios
			.post(url, request)
			.then((response) => {
				dispatch("updateAjaxDialog", [false, false]);
				return response.data?.status === "success";
			})
			.catch((err) => {
				HELPERS.handleError([
					err,
					i18n.t("common.error"),
					i18n.t("modules.op.texts.send_error"),
				]);
				return false;
			});

		return success;
	},

	async OpAxiosEditMessageInChat({ dispatch }, messageObj) {
		dispatch("updateAjaxDialog", [true, true]);
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/message/edit/`;

		const success = await axios
			.post(url, messageObj)
			.then((response) => {
				dispatch("updateAjaxDialog", [false, false]);
				return response.data?.status === "success";
			})
			.catch((err) => {
				HELPERS.handleError([
					err,
					i18n.t("common.error"),
					i18n.t("modules.op.texts.send_error"),
				]);
				return false;
			});

		return success;
	},

  async OpAxiosLeaveChat({ dispatch }, { chatRoomId, participantId, ...restData }) {
    // participantId - from chat partcipants - current chat my participant id
    const roomId = parseInt(chatRoomId);
    const request = {
      chat_room_id: roomId,
      participants: [participantId],
      ...restData,
    };
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/remove/`;

		dispatch("updateAjaxDialog", [true, true]);
    return await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_exit")]);
        return false;
      });
  },

  async OpAxiosConnectToChat({ dispatch }, query) {
    dispatch("updateAjaxDialog", [true, true]);

    const { roomId, userId, forceConnected } = query;
    const chatRoomId = parseInt(roomId);

    const request = {
      chat_room_id: chatRoomId,
      participants: [
        {
          type: "user",
          user_id: userId,
        },
      ],
    };

    if (forceConnected) {
      request.force_connected = forceConnected;
    }

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/`;

    const responseData = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return err.response.data;
      });

    return responseData;
  },

	async OpAxiosTransferToSkillGroup({ dispatch }, query) {
		dispatch("updateAjaxDialog", [true, true]);

		const { chat_room_id, skill_group_id } = query;

		const request = {
			chat_room_id: chat_room_id,
			skill_group_id: skill_group_id,
		};

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${chat_room_id}/transfer_skill_group/`;

		return await axios
			.post(url, request)
			.then((response) => {
				dispatch("updateAjaxDialog", [false, false]);
				return response.data;
			})
			.catch((err) => {
				dispatch('updateAjaxDialogError', [
					err,
					i18n.t('common.error'),
					i18n.t('modules.op.transfer_modal.transfer_skill_group_error'),
				]);
				return err.response.data;
			});
	},

  async opGetQuickReplies({ commit }, data) {
		const { bot_id = null, user_id } = {...data}
		const params = {
			user_id: user_id,
		}

    let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/quick_replies/list/`;
		if(bot_id) params['bot_id'] = bot_id

    const quickReplies = await axios({
      url,
      method: 'GET',
			params: params,
    })
      .then((response) => {
        return response.data?.data?.quick_replies || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_QUICK_REPLIES", quickReplies);
  },

	async opGetQuickRepliesIsPrompt({ commit }) {
		let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/quick_replies/list/`;
		url = `${url}?is_prompt=true`

		const quickReplies = await axios({
			url,
			method: 'GET',
		})
			.then((response) => {
				return response.data?.data?.quick_replies || [];
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});

		commit("OP_SET_QUICK_REPLIES_IS_PROMPT", quickReplies);
	},

  async opGetQuickReplyCategories({ commit }, data) {
		const { bot_id = null, user_id } = {...data}
		const params = {
			user_id: user_id,
		}

    let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/quick_reply_categories/list/`;
		if(bot_id) params['bot_id'] = bot_id

    const quickReplyCategories = await axios({
      url,
      method: 'GET',
			params: params,
    })
      .then((response) => {
        return response.data?.data?.categories || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_QUICK_REPLY_CATEGORIES", quickReplyCategories);
  },

  opActivateQuickReplies({ commit }, boolean) {
    commit("OP_ACTIVATE_QUICK_REPLIES", boolean);
  },

  opActivateAutoReplies({ commit }, boolean) {
    commit("OP_ACTIVATE_AUTO_REPLIES", boolean);
  },

	opSetRepliesMode({ commit, dispatch }, value) {
		if (value === 'quick-replies') {
			dispatch('opActivateQuickReplies', true)
			dispatch('opActivateAutoReplies', false);
		} else if (value === 'auto-replies') {
			dispatch('opActivateQuickReplies', false)
			dispatch('opActivateAutoReplies', true);
		} else {
			dispatch('opActivateQuickReplies', false)
			dispatch('opActivateAutoReplies', false);
		}

		commit("OP_SET_REPLIES_MODE", value);
	},

  opSetQuickReplyCategory({ commit }, category) {
    commit("OP_SET_QUICK_REPLY_CATEGORY", category);
  },

  async opAxiosGetChatStatuses ({commit}) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_statuses/list/`;

    const chatStatuses = await axios({
      url,
      method: 'GET',
    })
      .then((response) => {
        return response.data?.data?.statuses || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_CHAT_STATUSES", chatStatuses);
  },

  opSetReplyMessageData({ commit }, data) {
    commit('OP_SET_REPLY_MESSAGE_DATA', data)
  },
	opSetTransferDisabled({ commit }, value) {
    commit('OP_SET_TRANSFER_DISABLED', value)
  },
	setChatRoomTransfers({commit}, data) {
		commit('OP_SET_CHAT_ROOM_TRANSFERS', data)
	},

	opClearActiveChat({dispatch, commit}, destroy = null) {
		dispatch('opSetTopSidebarOpen', false);
		dispatch('opSetRightSidebarOpen', false);

		(destroy)
			? dispatch('opClearActiveChatRoomOnDestroy', null)
			: dispatch('opSetActiveChatRoom', null)

		commit('OP_CLEAR_CHAT_ROOM_MESSAGES');
	},
	opToggleHideSystemMessages({commit}, value) {
		commit('OP_TOGGLE_HIDE_SYSTEM_MESSAGES', value);
	},

	///chat_room/:chat_room_id/messages/read/
	async axiosActiveChatMakeRead({ getters }, data) {
		const { chat_room_ids, message_read } = data
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/messages/read/`;

		let params = {
			chat_room_ids: chat_room_ids,
			message_read: message_read
		}

		return await axios({
			url,
			method: 'POST',
			data: params,
		})
			.then((response) => {

				if(response?.data?.status === 'success') {
					for(let i in getters.opChatRooms) {
						if(chat_room_ids.includes(getters.opChatRooms[i].id)) {
							for(let j in getters.opChatRooms[i]?.messages) {
								getters.opChatRooms[i].messages[j].message_read = message_read
							}
						}
					}
				}

				return response
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});
	},

	opSetMessageListScrollDisabled({commit}, boolean) {
		commit('OP_SET_MESSAGE_LIST_SCROLL_DISABLED', boolean)
	},

	opSetEmailReplyTo({ commit }, array) {
		commit('OP_SET_EMAIL_REPLY_TO', array)
	},
	opSetEmailForwardTo({ commit }, array) {
		commit('OP_SET_EMAIL_FORWARD_TO', array)
	},
	async opGetEmailContactsList({ commit, getters }, withContactList = false) {
		const messagesList = getters.operator_panel_active_chat.messageList
		const contacts = []

		if(messagesList.length){
			const parsedMessagesList = JSON.parse(JSON.stringify(messagesList))
			for (let i in parsedMessagesList) {

				if(parsedMessagesList[i]?.data?.email_from) {
					const email = parsedMessagesList[i].data.email_from

					const obj = {
						first_name: email.split('@')[0],
						email: email
					}

					if(!contacts.some(el => el.email === obj.email)) {
						contacts.push(obj)
					}
				}

				if(parsedMessagesList[i]?.data?.metadata?.recipients?.length) {
					const recipients = parsedMessagesList[i].data.metadata.recipients

					for (let idx in recipients) {
						if (recipients[idx]?.email && !contacts.some(obj => obj.email === recipients[idx]?.email)) {
							contacts.push(recipients[idx]);  // Push the new object if it doesn't exist
						}

					}
				}
			}
		}

		if(withContactList) {
			const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/email/contacts_list/`;
			//TODO: send bot_id
			//const bot_id = getters.opActiveChatRoom?.bot_id
			const { data }  = await axios
				.get(url,
					{
						params: {
							limit: 1000,
							search: '',
						}
					})
				.then((response) => {
					return {
						data: response.data?.data || [],
					}
				})
				.catch((err) => {
					HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
					return [];
				});

			// const newArrayOfObj = data
			// 	.map(({
			// 					first_name: name,
			// 					...rest
			// 				}) => ({
			// 		name,
			// 		...rest
			// 	}));


			commit('OP_SET_EMAIL_CONTACTS_LIST', [...contacts, ...data])
		} else {
			commit('OP_SET_EMAIL_CONTACTS_LIST', contacts)
		}
	},

	async opCreateEmailContact(_, data) {
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/email/create_contact/`;

		const request = {
			email: data.email,
			first_name: data.first_name,
			bot_id: data.bot_id,
		}

		const { response }  = await axios
			.post(url, request)
			.then((response) => {
				return {
					response: response.data?.data || [],
				}
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});

		return response
	},

	async opAxiosGetDialogInfo({ commit }, chat_room_id) {
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${chat_room_id}/info`;

		return await axios({
			url,
			method: 'GET',
		})
			.then((response) => {
				if(response.data.data) {
					commit('OP_SET_ACTIVE_CHAT_INFO', response.data.data)
				}

				return response
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});
	},
	async opAxiosEditDialogInfo({ commit }, data) {
		const {chat_room_id, params} = {...data}

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${chat_room_id}/edit`;

		return await axios({
			url,
			method: 'POST',
			data: params,
		})
			.then((response) => {
				console.log(response)
				return response
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
			});
	},
};

const getters = {
  operator_panel_active_chat: (state) => {
    return state.operator_panel.active_chat;
  },

  opIsChatFrame: (state) => state.operator_panel.chatDisplayMode === "frame",

  opQuickReplies: (state) => {
    return state.operator_panel.active_chat.quickReplies;
  },
	opQuickRepliesIsPrompt: (state) => {
		return state.operator_panel.active_chat.quickRepliesIsPrompt;
	},

  opQuickReplyCategories: (state) => {
    return state.operator_panel.active_chat.quickReplyCategories;
  },

  opIsQuickRepliesActive: (state) => {
    return state.operator_panel.active_chat.isQuickRepliesActive;
  },

  opIsAutoRepliesActive: (state) => {
    return state.operator_panel.active_chat.isAutoRepliesActive;
  },
	opGetRepliesMode: (state) => {
		return state.operator_panel.active_chat.replies_mode;
	},

  opQuickReplyCategory: (state) => {
    return state.operator_panel.active_chat.quickReplyCategory;
  },

  opChatStatuses: (state) => {
    return state.operator_panel.active_chat.chatStatuses;
  },

  opReplyMessageData: (state) => {
    return state.operator_panel.active_chat.replyMessageData;
  },
	opGetChatRoomClientId: (state) => state.operator_panel.active_chat.client_id,
	opGetChatRoomStatus: (state) => state.operator_panel.active_chat.status_chat,
	opChatRoomTransfers: (state) => state.operator_panel.chatRoomTransfers,
	opHideSystemMessages: (state) => state.operator_panel.active_chat.hideSystemMessages,
	opMessageListScrollDisabled: (state) => state.operator_panel.active_chat.messageListScrollDisabled,

	opEmailContactsList: (state) => state.operator_panel.active_chat.emailContactsList,
	opEmailUserInputReplyTo: (state) => state.operator_panel.active_chat.emailUserInput.replyTo,
	opEmailUserInputForwardTo: (state) => state.operator_panel.active_chat.emailUserInput.forwardTo,

	opChatRoomInfo: (state) => state.operator_panel.active_chat.chatRoomInfo,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
